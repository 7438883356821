import React from 'react'
import { CFImage, CFView, DefaultScreen, MobileScreen } from 'components'
import { about, aboutDivider, mobileAbout, mobileAboutDivider } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center>
          <CFImage
            src={mobileAbout}
            w="100%"
            alt="Sushi Zion Japanese Restaurant About"
          />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView>
          <CFImage
            src={about}
            w="100%"
            alt="Sushi Zion Japanese Restaurant About"
          />
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
