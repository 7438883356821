import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import { hero, heroText, mobileHero, mobileHeroText } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="100VH"
          w="100%"
          image={`url(${mobileHero}) bottom / cover no-repeat`}
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
          column
          justifyBetween
          alignCenter
        >
          <Header />
          <CFView textCenter column center w="100%" ph="7%" pt="10px" pb="10px">
            {/* <CFImage
              h="155px"
              src={mobileHeroText}
              alt="Sushi Zion Japanese Restaurant Logo"
              zIndex={98}
            /> */}
            <CFView mt="10px" mb="10px" pulsate>
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          maxHeight="680px"
          w="100%"
          image={`url(${hero}) center / cover no-repeat`}
          zIndex={90}
          column
          justifyBetween
          alignCenter
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
        >
          <Header />
          <CFView
            row
            center
            alignCenter
            h="50px"
            w="65%"
            maxWidth="1400px"
            bg="#00546F"
          >
            <CFImage
              w="50%"
              src={heroText}
              alt="Sushi Zion Japanese Restaurant Logo"
              zIndex={98}
            />
            <CFView pt="5px" ml="20px" pulsate>
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
